import React, {forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState} from 'react';
import styled from 'styled-components';
import {gsap, ScrollTrigger} from "@/utils/gsap";
import useInView from "@/hooks/useInView";
import useIsDebug from "@/hooks/useIsDebug";
import {getIdWithoutSpecialSymbols, isFunction, isUndefined} from "@/utils/utils";
import dynamic from "next/dynamic";

const ReactPlayer = dynamic(() => import("react-player/lazy"), {
	ssr: false
})


const Wrapper = styled.div`
	display: flex;
	width: 100%;
	max-width: 100vw;
	height: 100%;
	position: relative; 
	z-index: 1;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	-webkit-transform-style: preserve-3d;
	overflow: hidden;
	margin: 0;
	
	figure {
        display: flex;
        position: relative;
        margin: 0;
	}
	
	&.disable-pointer-events{
        iframe {
            pointer-events: none;
        }
        figure{
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }	
	}
	
	video {
		object-fit: cover;
	}
	//opacity: 0;
`;

gsap.registerPlugin(ScrollTrigger)


const Player = ({
	src= '',
	playsinline,
	muted=true,
	playing=false,
	controls,
	resetOnPause,
	onPause,
	onPlay
}) => {
	const PlayerRef = useRef()

	return (
		<ReactPlayer
			ref={PlayerRef}
			url={src}
			width="100%"
			height="100%"
			wrapper="figure"
			muted={muted}
			volume={0.8}
			loop
			playsinline={playsinline}
			playing={playing}
			onPause={() => {
				if(isFunction(onPause)){
					onPause()
				}
				if(controls || !resetOnPause) return
				if(PlayerRef.current.seekTo){
					PlayerRef.current.seekTo(0)
				}
			}}
			onPlay={() => {
				if(isFunction(onPlay)){
					onPlay()
				}
			}}
			fallback={null}
			controls={controls}
		/>
	)
}

const Video = forwardRef(
	function Video({
		id='',
		src = '',
		className='',
		autoplay=false,
		visible=true,
		playing=undefined,
		muted=true,
		controls=false,
		playsinline=true,
		target: tg= { current: undefined },
		resetOnPause = true,
		onPause = () => {},
		onPlay = () => {},
		children,
		...props
	},
	fref)
	{
		const standardizedId = getIdWithoutSpecialSymbols(`video-${id}`)
		const WrapperRef = useRef(null);
		const debug = useIsDebug()
		const target = tg || WrapperRef
		const { inView } = useInView(target, {
			markers: debug,
			disabled: !isUndefined(playing)
		})
		const [hover, setHover] = useState(false)
		const isPlaying = useMemo(() => {
			if(!isUndefined(playing)) return playing
			if(autoplay){
				if(autoplay === 'force') return true
				return inView
			}
			return hover;

		},[inView, hover, autoplay, playing])

		useImperativeHandle(fref, () => WrapperRef.current, []);

		const handlePointerEnter = useCallback(() => {
			setHover(true)
		},[])

		const handlePointerLeave = useCallback(() => {
			setHover(false)
		},[])

		return (
			<Wrapper
				id={standardizedId}
				ref={WrapperRef}
				className={`${className} ${controls ? '' : 'disable-pointer-events'} video`}
				onPointerEnter={handlePointerEnter}
				onPointerLeave={handlePointerLeave}
				{...props}
			>
				<Player
					key={src}
					src={src}
					playing={isPlaying}
					muted={muted}
					playsinline={playsinline}
					controls={controls}
					resetOnPause={resetOnPause}
					onPause={onPause}
					onPlay={onPlay}
				/>
				{children}
			</Wrapper>
		);
	})

export default memo(Video);
