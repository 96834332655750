import React, {useMemo, useRef} from 'react';
import styled from 'styled-components';
import {gsap, useGSAP} from "@/utils/gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import Video from "@/components/common/Video";
import {getPaddingsClassFromData} from "@/utils/utils";
import {useBranch} from "@/hooks/useBranch";
import useGlobals from "@/hooks/useGlobals";
import {GLOBAL_STATE} from "@/constants";

const CommonVideo = styled(Video)`
	overflow: visible;
	position: relative;
    aspect-ratio: inherit;
`

const VideoFallback = styled.div`
	position: relative;
	width: 100%;
    aspect-ratio: inherit;
	background-color: ${({ theme }) => theme.colors.chilliRed};
`

const VideoContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	position: relative;
	place-items: center;
	width: 100%;
	margin: 0;
    aspect-ratio: 16/9;

`

gsap.registerPlugin(ScrollTrigger);
function useGetCurrentStateData() {
	const allStates = useGlobals(state => state.states)
	const {selectedOption} = useBranch();
	return allStates.find(state => (state?.handle || '') === selectedOption)
}
const ExpandingVideo = ({data}) => {
	const videoRef = useRef();
	const paddings = getPaddingsClassFromData(data);
	const { selectedOption } = useBranch()
	const state = useGetCurrentStateData()
	const shouldDisplayGlobalVideo = state?.shouldDisplayGlobalVideo || false;
	const stateToFilterBy = shouldDisplayGlobalVideo ? GLOBAL_STATE : selectedOption;
	const videoData = useMemo(() => {
		return data?.video?.filter((item) => item?.state?.handle?.toLowerCase() === stateToFilterBy)
	}, [data, selectedOption])
	const videoUrl = videoData?.[0]?.videoUrl?.url || '';
	const videoId = videoData?.id || '';

	useGSAP(() => {
		gsap.fromTo(videoRef.current, {
			width: '50%',
		},
		{
			scrollTrigger: {
				trigger: '#video-section',
				start: 'top bottom',
				end: `bottom bottom `,
				scrub: 0.5,
				// markers: true,
				invalidateOnRefresh: true,
			},
			width: '101%',
			ease: 'linear'
		})
	}, {
		dependencies: [selectedOption],
		revertOnUpdate: true
	})
	return (
		<VideoContainer
			id='video-section'
			className={paddings}
		>
			{videoUrl.length > 0 ?  (
				<CommonVideo
					id={videoId}
					src={videoUrl}
					autoplay={true}
					className={'expand-on-scroll'}
					ref={videoRef}
					target="#video-section"
				/>
			) : (
				<VideoFallback/>
			)}
		</VideoContainer>
	)
};

export default ExpandingVideo;
